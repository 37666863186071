import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Button, Col, Flex, Radio, Row, Select, Table } from "antd";

import { GET } from "../../../providers/useAxiosQuery";
import { apiUrl, role } from "../../../providers/companyInfo";
import { useTableScrollOnTop } from "../../../providers/CustomTableFilter";

export default function PageGradeFileReports(props) {
	const { refetchPermissions } = props;

	const location = useLocation();

	const [tableFilter, setTableFilter] = useState({
		page: 1,
		page_size: 50,
		search: "",
		sort_field: "created_at",
		sort_order: "desc",
		isTrash: 0,
		type: "",
		department_id: "",
	});

	const { data: dataDepartments } = GET(
		`api/department`,
		"department_status",
		(res) => {},
		false
	);

	const { data: dataSource, refetch: refetchSource } = GET(
		`api/grade_file?${new URLSearchParams(tableFilter)}`,
		"grade_file_report_list",
		() => {
			refetchPermissions();
		}
	);

	useEffect(() => {
		refetchSource();

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tableFilter]);

	const onChangeTable = (pagination, filters, sorter) => {
		setTableFilter((prevState) => ({
			...prevState,
			sort_field: sorter.columnKey,
			sort_order: sorter.order ? sorter.order.replace("end", "") : null,
			page: 1,
			page_size: "50",
		}));
	};

	useTableScrollOnTop("tbl1", location.pathname);

	return (
		<Row gutter={[20, 20]} id="tbl_wrapper">
			<Col xs={24} sm={24} md={24} lg={24}>
				<Flex gap={15} align="center">
					{role() !== 3 ? (
						<Select
							label="Department"
							placeholder="Department"
							className="w-20"
							size="large"
							allowClear
							options={
								dataDepartments
									? dataDepartments.data.map((item) => {
											return {
												label: item.department_name,
												value: item.id,
											};
									  })
									: []
							}
							onChange={(value) => {
								setTableFilter((prevState) => ({
									...prevState,
									department_id: value ? value : "",
								}));
							}}
						/>
					) : null}

					<Radio.Group
						onChange={(e) =>
							setTableFilter((ps) => ({
								...ps,
								type: e.target.value,
							}))
						}
					>
						<Radio value="With INC">With INC</Radio>
						<Radio value="Without INC">Without INC</Radio>
					</Radio.Group>

					<Button
						className="btn-main-primary"
						onClick={() => {
							window.open(
								apiUrl(
									`api/grade_file_report?${new URLSearchParams(tableFilter)}`
								),
								"_blank"
							);
						}}
					>
						Generate Report
					</Button>
				</Flex>
			</Col>

			<Col xs={24} sm={24} md={14} lg={24}>
				<Table
					id="tbl1"
					className="ant-table-default ant-table-striped"
					dataSource={dataSource && dataSource.data.data}
					rowKey={(record) => record.id}
					pagination={false}
					bordered={false}
					onChange={onChangeTable}
					scroll={{ x: "max-content" }}
					sticky
				>
					<Table.Column
						title="Status"
						key="status"
						dataIndex="status"
						sorter
						align="center"
						width={180}
					/>
					<Table.Column
						title="Notes"
						key="description"
						dataIndex="description"
						sorter
						width={220}
					/>
					<Table.Column
						title="Class Time"
						key="class_time"
						dataIndex="class_time"
						sorter
						width={180}
					/>
					<Table.Column
						title="Name"
						key="fullname"
						sorter
						dataIndex="fullname"
						width={220}
					/>

					<Table.Column
						title="Room"
						key="room_code"
						dataIndex="room_code"
						sorter
						width={180}
					/>
					<Table.Column
						title="Subject"
						key="code"
						dataIndex="code"
						sorter
						width={180}
					/>
					<Table.Column
						title="School Year"
						key="school_year"
						dataIndex="school_year"
						sorter
						width={180}
					/>
					<Table.Column
						title="Semester"
						key="semester"
						dataIndex="semester"
						sorter
						width={180}
					/>
				</Table>
			</Col>
		</Row>
	);
}
