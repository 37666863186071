import { Card, Col, Row, Statistic } from "antd";
import CountUp from "react-countup";

const formatter = (value) => <CountUp end={value} separator="," />;

export default function FacultyMonitoringListGraph(props) {
	const { dataSource } = props;

	const RenderGraph = () => {
		let renderData = [];

		if (dataSource) {
			dataSource.forEach((item, index) => {
				renderData.push(
					<Col xs={24} sm={12} md={8} lg={6} xl={6} xxl={6} key={index}>
						<Card title={item.label} extra={item.extra}>
							<Statistic
								// title="Active Users"
								value={item.count}
								formatter={formatter}
							/>
						</Card>
					</Col>
				);
			});
		}

		return <Row gutter={[20, 20]}>{renderData}</Row>;
	};

	return <RenderGraph />;
}
