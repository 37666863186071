import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Button, Col, Flex, Form, Row, Table } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faFileCirclePlus } from "@fortawesome/pro-regular-svg-icons";

import { GET } from "../../../providers/useAxiosQuery";
import { apiUrl, userData } from "../../../providers/companyInfo";
import {
	TableGlobalSearchAnimated,
	TablePageSize,
	TablePagination,
	TableShowingEntriesV2,
	useTableScrollOnTop,
} from "../../../providers/CustomTableFilter";
import ModalUploadFile from "./components/ModalUploadFile";
import ModalUploadFileStatusPreview from "./components/ModalUploadFileStatusPreview";
import FloatSelect from "../../../providers/FloatSelect";

export default function PageGradeFile(props) {
	const { refetchPermissions } = props;

	const location = useLocation();

	const [toggleModalFormUploadFile, setToggleModalFormUploadFile] = useState({
		open: false,
		data: null,
	});
	const [toggleModalFormUploadFileShow, setToggleModalFormUploadFileShow] =
		useState({
			open: false,
			data: null,
		});

	const [tableFilter, setTableFilter] = useState({
		page: 1,
		page_size: 50,
		search: "",
		sort_field: "created_at",
		sort_order: "desc",
		isTrash: 0,
		user_id: userData().id,
		from: "grade_file",
		profile_id: "",
	});

	const { data: dataSourceFaculty } = GET(
		`api/profile?from=grade_file`,
		"faculty_load_profile_list"
	);

	const { data: dataSource, refetch: refetchSource } = GET(
		`api/faculty_load?${new URLSearchParams(tableFilter)}`,
		"faculty_load_list",
		() => {
			refetchPermissions();
		}
	);

	useEffect(() => {
		refetchSource();

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tableFilter]);

	const onChangeTable = (pagination, filters, sorter) => {
		setTableFilter((prevState) => ({
			...prevState,
			sort_field: sorter.columnKey,
			sort_order: sorter.order ? sorter.order.replace("end", "") : null,
			page: 1,
			page_size: "50",
		}));
	};

	useTableScrollOnTop("tblGradeFileList", location.pathname);

	return (
		<Row gutter={[15, 15]} id="tbl_wrapper">
			<Col xs={24} sm={24} md={24}>
				<Form>
					<Row>
						<Col xs={24} sm={24} md={6}>
							<Form.Item name="profile_id" noStyle>
								<FloatSelect
									label="Faculty"
									placeholder="Faculty"
									allowClear
									options={
										dataSourceFaculty && dataSourceFaculty.data.length > 0
											? dataSourceFaculty.data.map((item) => ({
													label: item.fullname,
													value: item.id,
											  }))
											: []
									}
									onChange={(e) =>
										setTableFilter((ps) => ({
											...ps,
											profile_id: e ? e : "",
										}))
									}
								/>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Col>

			<Col xs={24} sm={24} md={24}>
				<div className="tbl-top-filter">
					<Flex gap={15}>
						<Button
							className={`btn-main-primary min-w-150 ${
								tableFilter.isTrash === 0 ? "active" : "outlined"
							}`}
							onClick={() => {
								setTableFilter((ps) => ({
									...ps,
									isTrash: 0,
								}));
							}}
						>
							Active
						</Button>

						<Button
							className={`btn-main-primary min-w-150 ${
								tableFilter.isTrash === 1 ? "active" : "outlined"
							}`}
							onClick={() => {
								setTableFilter((ps) => ({
									...ps,
									isTrash: 1,
								}));
							}}
						>
							Archived
						</Button>
					</Flex>

					<TablePageSize
						tableFilter={tableFilter}
						setTableFilter={setTableFilter}
					/>
				</div>
			</Col>

			<Col xs={24} sm={24} md={24}>
				<div className="tbl-top-filter">
					<TableGlobalSearchAnimated
						tableFilter={tableFilter}
						setTableFilter={setTableFilter}
					/>

					<Flex gap={15}>
						<TableShowingEntriesV2 />

						<TablePagination
							tableFilter={tableFilter}
							setTableFilter={setTableFilter}
							total={dataSource?.data.total}
							showLessItems={true}
							showSizeChanger={false}
							tblIdWrapper="tbl_wrapper"
						/>
					</Flex>
				</div>
			</Col>

			<Col xs={24} sm={24} md={24}>
				<Table
					id="tblGradeFileList"
					className="ant-table-default ant-table-striped"
					dataSource={dataSource && dataSource.data.data}
					rowKey={(record) => record.id}
					pagination={false}
					bordered={false}
					onChange={onChangeTable}
					scroll={{ x: "max-content" }}
					sticky
				>
					<Table.Column
						title="Preview Attachment"
						key="preview_attachment"
						align="center"
						width={120}
						render={(_, record) => {
							return (
								<Button
									type="link"
									className="color-1"
									onClick={() => {
										let grade_files = record.grade_files;
										let profile = record.profile;
										let attachmentsSig = profile.attachments.filter(
											(f) => f.file_description === "Signature"
										);
										let pdf_file = null;
										let grade_file = {};

										if (grade_files.length > 0) {
											grade_file = grade_files[0];
											let attachments = grade_files[0].attachments.filter(
												(f) => f.file_description === "Grade File"
											);

											if (attachments.length > 0) {
												pdf_file = attachments[0].pdf_file;
											}
										}

										let signature = null;

										if (attachmentsSig.length > 0) {
											signature = apiUrl(attachmentsSig[0].file_path);
										}

										setToggleModalFormUploadFileShow({
											open: true,
											data: { ...grade_file, pdf_file, signature },
										});
									}}
								>
									<FontAwesomeIcon icon={faEye} />
								</Button>
							);
						}}
					/>

					<Table.Column
						title="Upload Grade"
						key="upload_grade"
						align="center"
						width={100}
						render={(text, record) => {
							return (
								<Button
									type="link"
									className="color-1"
									onClick={() => {
										setToggleModalFormUploadFile({
											open: true,
											data: record,
										});
									}}
									icon={<FontAwesomeIcon icon={faFileCirclePlus} />}
								/>
							);
						}}
					/>

					<Table.Column
						title="Status"
						key="gradeFileStatus"
						dataIndex="gradeFileStatus"
						align="center"
						width={100}
						sorter
					/>

					<Table.Column
						title="Remarks"
						key="gradeFileRemarks"
						dataIndex="gradeFileRemarks"
						width={150}
						sorter
					/>

					<Table.Column
						title="Class Time Start"
						key="time_in"
						dataIndex="time_in"
						width={150}
						sorter
					/>

					<Table.Column
						title="Class Time End"
						key="time_out"
						dataIndex="time_out"
						width={150}
						sorter
					/>

					<Table.Column
						title="Meridian"
						key="meridian"
						dataIndex="meridian"
						width={150}
						sorter
					/>

					<Table.Column
						title="Name"
						key="fullname"
						sorter
						dataIndex="fullname"
						width={150}
					/>

					<Table.Column
						title="Room"
						key="room_code"
						dataIndex="room_code"
						width={120}
						sorter
					/>

					<Table.Column
						title="Subject"
						key="code"
						dataIndex="code"
						width={120}
						sorter
					/>

					<Table.Column
						title="School Year"
						key="school_year"
						dataIndex="school_year"
						width={150}
						sorter
					/>

					<Table.Column
						title="Semester"
						key="semester"
						dataIndex="semester"
						width={150}
						sorter
					/>
				</Table>
			</Col>
			<Col xs={24} sm={24} md={24}>
				<div className="tbl-bottom-filter">
					<TableShowingEntriesV2 />
					<TablePagination
						tableFilter={tableFilter}
						setTableFilter={setTableFilter}
						total={dataSource?.data.total}
						showLessItems={true}
						showSizeChanger={false}
						tblIdWrapper="tbl_wrapper"
					/>
				</div>
			</Col>

			<ModalUploadFile
				toggleModalFormUploadFile={toggleModalFormUploadFile}
				setToggleModalFormUploadFile={setToggleModalFormUploadFile}
			/>
			<ModalUploadFileStatusPreview
				toggleModalFormUploadFileShow={toggleModalFormUploadFileShow}
				setToggleModalFormUploadFileShow={setToggleModalFormUploadFileShow}
			/>
		</Row>
	);
}
