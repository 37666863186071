import { useEffect, useState } from "react";

import { GET } from "../../../../providers/useAxiosQuery";

export default function CollapseItemFacultyMonitoringDepartmentTopGraph(props) {
	const { Highcharts } = props;

	const [filter, setFilter] = useState({
		action: "department",
		department: "",
		faculty: "",
		year: "",
		quarter: "",
		month: "",
		day: "",
	});

	const { refetch: refetchSource } = GET(
		`api/faculty_load_monitoring_faculty_department_top_graph?${new URLSearchParams(
			filter
		)}`,
		"faculty_load_monitoring_faculty_department_top_graph",
		(res) => {
			console.log("faculty_load_monitoring_faculty_department_top_graph", res);

			if (res.data) {
				let data = res.data;
				let action = data.action;
				let downTo = data.downTo;

				let title = "TOP DEPARTMENT ABSENT";
				let subtitleText = "CLICK THE COLUMNS TO VIEW PER FACULTY";
				let xAxisTitle = "DEPARTMENT";

				if (action === "department") {
					title = "TOP FACULTY ABSENT";
					subtitleText = "CLICK THE COLUMNS TO VIEW PER FACULTY";
					xAxisTitle = "DEPARTMENT";
				} else if (action === "faculty") {
					title = "TOP FACULTY ABSENT";
					subtitleText = "CLICK THE COLUMNS TO VIEW PER YEAR";
					xAxisTitle = "FACULTY";
				} else if (action === "year") {
					title = "YEARLY";
					subtitleText = "CLICK THE COLUMNS TO VIEW PER QUARTER";
					xAxisTitle = "YEAR";
				} else if (action === "quarter") {
					title = "QUARTERLY";
					subtitleText = "CLICK THE COLUMNS TO VIEW PER MONTH";
					xAxisTitle = "QUARTER";
				} else if (action === "month") {
					title = "MONTHLY";
					subtitleText = "CLICK THE COLUMNS TO VIEW PER DAY";
					xAxisTitle = "MONTH";
				} else if (action === "day") {
					title = "DAILY";
					subtitleText = "CLICK THE COLUMNS TO GO BACK TO PER DEPARTMENT";
					xAxisTitle = "DAY";
				}

				let data_series_value_filter = data.data_series_value.filter(
					(f) => f.y !== 0
				);

				if (data_series_value_filter.length === 0) {
					subtitleText = "NO DATA TO VIEW";
				}

				if (document.getElementById("divFacultyMonitoringDepartmentTopGraph")) {
					let chart = Highcharts.chart(
						"divFacultyMonitoringDepartmentTopGraph",
						{
							chart: {
								type: "column",
								events: {
									click: function (e) {
										let series_name =
											data.data_series_name[
												Math.abs(Math.round(e.xAxis[0].value))
											];

										if (action === "day") {
											setFilter((prevState) => ({
												...prevState,
												action: downTo,
											}));
										} else {
											setFilter((prevState) => ({
												...prevState,
												action: downTo,
												[action]: series_name,
											}));
										}

										let graphWrapper = document.querySelector(
											"#divFacultyMonitoringDepartmentTopGraphWrapper"
										);
										if (graphWrapper) {
											let highchartsDataTable = graphWrapper.querySelector(
												".highcharts-data-table"
											);
											if (highchartsDataTable) {
												highchartsDataTable.remove();
											}
										}
									},
								},
							},
							title: {
								text: title,
							},
							subtitle: {
								text: subtitleText,
							},
							xAxis: {
								title: {
									text: xAxisTitle,
									enabled: false,
								},
								categories: data.data_series_name,
								crosshair: true,
								type: "category",
							},
							yAxis: {
								title: {
									text: null,
								},
								// labels: {
								// 	formatter: function () {
								// 		return `${Highcharts.numberFormat(this.value, 0, "", ",")}`;
								// 	},
								// },
							},
							tooltip: {
								headerFormat:
									'<span style="font-size:10px">{point.key}</span><table>',
								pointFormat:
									'<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
									'<td style="padding:0"><b>{point.y}</b></td></tr>',
								footerFormat: "</table>",
								shared: true,
								useHTML: true,
							},
							legend: false,
							plotOptions: {
								series: {
									borderWidth: 0,
									dataLabels: {
										enabled: false,
										// format: "{point.y:.2f}",
									},
									cursor: "pointer",
								},
								column: {
									pointPadding: 0.2,
									borderWidth: 2,
									dataLabels: {
										enabled: false,
										// format: "{point.y:.0f}",
										formatter: function () {
											if (this.y === 0) {
												return null;
											}
											return this.y.toFixed(2);
										},
									},
									events: {
										click: function (e) {
											if (action === "day") {
												setFilter((prevState) => ({
													...prevState,
													action: downTo,
												}));
											} else {
												setFilter((prevState) => ({
													...prevState,
													action: downTo,
													[action]: e.point.category,
												}));
											}

											let graphWrapper = document.querySelector(
												"#divFacultyMonitoringDepartmentTopGraphWrapper"
											);
											if (graphWrapper) {
												let highchartsDataTable = graphWrapper.querySelector(
													".highcharts-data-table"
												);
												if (highchartsDataTable) {
													highchartsDataTable.remove();
												}
											}
										},
									},
								},
							},
							series: [
								{
									name: "FACULTY TOP",
									colorByPoint: true,
									data: data.data_series_value,
								},
							],
							exporting: {
								filename: `report-revenue-${title.toLowerCase()}`,
								buttons: {
									contextButton: {
										symbolStroke: "#f58d13",
										symbolX: 14,
										menuItems: [
											"printChart",
											"separator",
											"downloadPNG",
											"downloadJPEG",
											"downloadPDF",
											"downloadSVG",
											"separator",
											// "downloadCSV",
											"toggleTable",
										],
									},
								},
								menuItemDefinitions: {
									// Custom definition
									toggleTable: {
										onclick: function () {
											if (
												this.dataTableDiv &&
												this.dataTableDiv.style.display !== "none"
											) {
												this.dataTableDiv.style.display = "none";
											} else {
												this.viewData();
												this.dataTableDiv.style.display = "";
											}
										},
										text: "View Data Table",
									},
								},
							},
						}
					);

					Highcharts.addEvent(chart, "aftergetTableAST", function (e) {
						e.tree.children[2].children.forEach(function (row) {
							row.children.forEach(function (cell, i) {
								if (i !== 0) {
									row.children[i].textContent = cell.textContent;
								}
							});
						});
					});
				}
			}
		},
		false
	);

	useEffect(() => {
		refetchSource();

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filter]);

	return (
		<div id="divFacultyMonitoringDepartmentTopGraphWrapper">
			<div id="divFacultyMonitoringDepartmentTopGraph" />
		</div>
	);
}
