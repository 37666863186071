import { useContext } from "react";
import { Button, Col, Flex, Popconfirm, Row, Table } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faScreenUsers } from "@fortawesome/pro-regular-svg-icons";

import {
	TableGlobalSearchAnimated,
	TablePageSize,
	TablePagination,
	TableShowingEntriesV2,
} from "../../../../providers/CustomTableFilter";
import FacultyLoadContext from "./FacultyLoadContext";

export default function TableFacultyLoadList(props) {
	const {
		selectedRowKeys,
		setSelectedRowKeys,
		tableFilter,
		setTableFilter,
		dataSource,
		onChangeTable,
		setToggleModalFormFacultyLoad,
		isLoadingDeleteFacultyLoad,
		handleSelectedArchived,
	} = useContext(FacultyLoadContext);

	return (
		<Row gutter={[12, 12]}>
			<Col xs={24} sm={24} md={24}>
				<div className="tbl-top-filter">
					<Flex gap={15}>
						<Button
							className={`btn-main-primary min-w-150 ${
								tableFilter.isTrash === 0 ? "active" : "outlined"
							}`}
							onClick={() => {
								setTableFilter((ps) => ({
									...ps,
									isTrash: 0,
								}));
								setSelectedRowKeys([]);
							}}
						>
							Active
						</Button>

						<Button
							className={`btn-main-primary min-w-150 ${
								tableFilter.isTrash === 1 ? "active" : "outlined"
							}`}
							onClick={() => {
								setTableFilter((ps) => ({
									...ps,
									isTrash: 1,
								}));
								setSelectedRowKeys([]);
							}}
						>
							Archived
						</Button>

						{selectedRowKeys.length > 0 && (
							<Popconfirm
								title={
									<>
										Are you sure you want to
										<br />
										{tableFilter.isTrash === 0 ? "archive" : "active"} the
										selected{" "}
										{selectedRowKeys.length > 1 ? "faculties" : "faculty"}?
									</>
								}
								okText="Yes"
								cancelText="No"
								onConfirm={() => {
									handleSelectedArchived(tableFilter.isTrash);
								}}
							>
								<Button
									className="btn-main-secondary"
									name="btn_active_archive"
									loading={isLoadingDeleteFacultyLoad}
								>
									{tableFilter.isTrash === 0 ? "ARCHIVE" : "ACTIVATE"} SELECTED
								</Button>
							</Popconfirm>
						)}
					</Flex>

					<Flex gap={15}>
						<TablePageSize
							tableFilter={tableFilter}
							setTableFilter={setTableFilter}
						/>
					</Flex>
				</div>
			</Col>
			<Col xs={24} sm={24} md={24}>
				<div className="tbl-top-filter">
					<TableGlobalSearchAnimated
						tableFilter={tableFilter}
						setTableFilter={setTableFilter}
					/>

					<Flex gap={15}>
						<TableShowingEntriesV2 />

						<TablePagination
							tableFilter={tableFilter}
							setTableFilter={setTableFilter}
							total={dataSource?.data.total}
							showLessItems={true}
							showSizeChanger={false}
							tblIdWrapper="tbl_wrapper"
						/>
					</Flex>
				</div>
			</Col>
			<Col xs={24} sm={24} md={24}>
				<Table
					id="tblFacultyLoadList"
					className="ant-table-default ant-table-striped"
					dataSource={dataSource && dataSource.data.data}
					rowKey={(record) => record.id}
					pagination={false}
					bordered={false}
					onChange={onChangeTable}
					scroll={{ x: "max-content" }}
					rowSelection={{
						selectedRowKeys,
						onChange: (selectedRowKeys) => {
							setSelectedRowKeys(selectedRowKeys);
						},
					}}
					sticky
				>
					<Table.Column
						title=""
						key="action"
						dataIndex="action"
						align="center"
						width={50}
						render={(text, record) => {
							return (
								<>
									<Button
										type="link"
										className="p-0 w-auto h-auto"
										onClick={() => {
											setToggleModalFormFacultyLoad({
												open: true,
												data: record,
											});
										}}
									>
										<FontAwesomeIcon icon={faScreenUsers} />
									</Button>
								</>
							);
						}}
					/>
					<Table.Column
						title="Faculty No"
						key="faculty_no"
						dataIndex="faculty_no"
						width={120}
						sorter
					/>
					<Table.Column
						title="Name"
						key="fullname"
						sorter
						dataIndex="fullname"
						width={200}
					/>
					<Table.Column
						title="Schedule"
						key="schedule"
						dataIndex="schedule"
						width={150}
						render={(_, record) => {
							let schedule = "";

							if (record.faculty_load_schedules.length) {
								schedule = record.faculty_load_schedules.map((item, index) => {
									return (
										<div
											key={index}
										>{`${item.time_in} - ${item.time_out} ${item.meridian} ${item.day_schedule}`}</div>
									);
								});
							}

							return schedule;
						}}
					/>

					<Table.Column
						title="Section"
						key="section"
						dataIndex="section"
						width={120}
						sorter
					/>
					<Table.Column
						title="Room"
						key="room_code"
						dataIndex="room_code"
						width={120}
						sorter
					/>
					<Table.Column
						title="Subject"
						key="code"
						dataIndex="code"
						width={130}
						sorter
					/>
					<Table.Column
						title="School Year"
						key="school_year"
						dataIndex="school_year"
						width={150}
						sorter
					/>
					<Table.Column
						title="Semester"
						key="semester"
						dataIndex="semester"
						width={150}
						sorter
					/>
				</Table>
			</Col>
			<Col xs={24} sm={24} md={24}>
				<div className="tbl-bottom-filter">
					<div />

					<Flex gap={15}>
						<TableShowingEntriesV2 />
						<TablePagination
							tableFilter={tableFilter}
							setTableFilter={setTableFilter}
							total={dataSource?.data.total}
							showLessItems={true}
							showSizeChanger={false}
							tblIdWrapper="tbl_wrapper"
						/>
					</Flex>
				</div>
			</Col>
		</Row>
	);
}
