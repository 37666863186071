import { useContext, useRef } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import multiMonthPlugin from "@fullcalendar/multimonth";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";

import FacultyLoadContext from "./FacultyLoadContext";

export default function CalendarFacultyLoadList() {
	const { dataCalendarView } = useContext(FacultyLoadContext);

	dayjs.extend(weekday);

	const calendarRef = useRef(null);

	let eventCount = 0;

	const currentDay = dayjs().format("ddd");

	return (
		<FullCalendar
			// ref to the calendar to access the calendar object
			ref={calendarRef}
			height={650}
			dayMaxEvents={true}
			displayEventTime={false}
			events={dataCalendarView}
			eventContent={(arg) => {
				// console.log("arg", arg);
				const { event } = arg;
				const { title } = event._def;
				const { color } = event._def.ui;
				const { room_code, code, time_in, time_out, meridian } =
					event._def.extendedProps;

				return (
					<div
						className="event-content"
						style={{
							backgroundColor: color,
							borderRadius: "5px",
							padding: "5px",
							color: "white",
						}}
					>
						{title}
						<br />
						{`${room_code} - ${code} - ${time_in} - ${time_out} ${meridian}`}
					</div>
				);
			}}
			plugins={[
				dayGridPlugin,
				timeGridPlugin,
				interactionPlugin,
				multiMonthPlugin,
			]}
			headerToolbar={{
				left: "prev,today,next",
				center: "title",
				right: "dayGridMonth,timeGridWeek,timeGridDay",
			}}
			views={{
				dayGridMonth: {},
				timeGridWeek: {
					nowIndicator: true,
					slotMinTime: "06:00:00",
					slotMaxTime: "22:00:00",
				},
				timeGridDay: {
					nowIndicator: true,
					slotMinTime: "06:00:00",
					slotMaxTime: "22:00:00",
				},
			}}
			// customize the day header Day 1st etc.
			// dayHeaderContent={(args) => {
			// 	return moment(args.date).format("ddd Do");
			// }}
			// count events of the days
			eventDidMount={({ event }) =>
				dayjs(event.start).isSame(dayjs(), "day") && eventCount++
			}
			datesSet={({ startStr, endStr, args }) => {
				document
					// highlight the current day, by adding a class to the header
					.querySelectorAll(".fc-col-header-cell")
					.forEach(
						(header) =>
							header.textContent === currentDay &&
							header.classList.add("current-day-header")
					);

				const start = new Date(startStr);

				document.querySelectorAll(".fc-day").forEach((cell, index) => {
					const date = new Date(start.getTime() + index * 24 * 60 * 60 * 1000);

					if (calendarRef.current) {
						const eventsOnThisDay = calendarRef.current
							.getApi()
							.getEvents()
							.filter((event) => event.start.getDate() === date.getDate());
						if (eventsOnThisDay.length > 0) {
							const badge = Object.assign(document.createElement("badge"), {
								className: "badge",
							});

							// Reset event count
							const countTextElement = cell.querySelector(".countText");
							const badgeElement = cell.querySelector(".badge");
							if (countTextElement) countTextElement.remove();
							if (badgeElement) badgeElement.remove();

							cell.append(badge);

							if (calendarRef.current.getApi().view.type !== "dayGridMonth") {
								const countText = Object.assign(
									document.createElement("span"),
									{
										className: "countText",
										textContent: ` ${eventsOnThisDay.length} Schedule`,
									}
								);
								cell.append(countText);
							}
						}
					}
				});
			}}
			// switch calendar view when event is clicked
			eventClick={({ view, event }) => {
				const currentView = view.type;
				let nextView =
					currentView === "dayGridMonth"
						? "timeGridWeek"
						: currentView === "timeGridWeek"
						? "timeGridDay"
						: "dayGridMonth";
				view.calendar.changeView(nextView);
				view.calendar.gotoDate(event.start);
			}}
		/>
	);
}
