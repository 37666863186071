import { Col, Collapse, Row } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/pro-regular-svg-icons";

import { GET } from "../../../../providers/useAxiosQuery";
import { userData } from "../../../../providers/companyInfo";
import FacultyMonitoringListGraph from "./FacultyMonitoringListGraph";
import FacultyMonitoringDepartmentListGraph from "./FacultyMonitoringDepartmentListGraph";
import CollapseItemFacultyMonitoringGraph from "./CollapseItemFacultyMonitoringGraph";
import CollapseItemFacultyMonitoringFacultyTopGraph from "./CollapseItemFacultyMonitoringFacultyTopGraph";
import FacultyMonitoringDepartmentPieGraph from "./FacultyMonitoringDepartmentPieGraph";
import CollapseItemFacultyMonitoringDepartmentTopGraph from "./CollapseItemFacultyMonitoringDepartmentTopGraph";
import FacultyMonitoringCalendar from "./FacultyMonitoringCalendar";
import CollapseItemFacultyMonitoringNegativeGraph from "./CollapseItemFacultyMonitoringNegativeGraph";

export default function FacultyMonitoringGraphList(props) {
	const { Highcharts } = props;

	const { data: dataSourceFacultyLoadMonitoringList } = GET(
		`api/faculty_load_monitoring_list_graph`,
		"faculty_load_monitoring_list_graph",
		(res) => {
			console.log("faculty_load_monitoring_list_graph", res);
		},
		false
	);

	return (
		<Row gutter={[20, 20]}>
			<Col
				xs={24}
				sm={24}
				md={24}
				lg={24}
				xl={24}
				xxl={24}
				className="card-list"
			>
				<FacultyMonitoringListGraph
					Highcharts={Highcharts}
					dataSource={
						dataSourceFacultyLoadMonitoringList &&
						dataSourceFacultyLoadMonitoringList.absent
							? dataSourceFacultyLoadMonitoringList.absent
							: []
					}
				/>
			</Col>

			<Col xs={24} sm={24} md={18} lg={18} xl={18} xxl={18}>
				<Collapse
					defaultActiveKey={["1", "2", "3", "4"]}
					expandIconPosition="end"
					expandIcon={({ isActive }) =>
						isActive ? (
							<FontAwesomeIcon icon={faAngleUp} />
						) : (
							<FontAwesomeIcon icon={faAngleDown} />
						)
					}
					items={
						![3, 4, 5].includes(userData().user_role_id)
							? [
									{
										key: "1",
										className: "collapse-main-primary",
										label: `TOP FACULTY ABSENT`,
										children: (
											<CollapseItemFacultyMonitoringFacultyTopGraph
												Highcharts={Highcharts}
											/>
										),
									},
									{
										key: "2",
										className: "collapse-main-primary",
										label: `DEPARTMENT TOP`,
										children: (
											<CollapseItemFacultyMonitoringDepartmentTopGraph
												Highcharts={Highcharts}
											/>
										),
									},
									{
										key: "3",
										className: "collapse-main-primary",
										label: `ABSENT/PRESENT`,
										children: (
											<CollapseItemFacultyMonitoringNegativeGraph
												Highcharts={Highcharts}
											/>
										),
									},
									{
										key: "4",
										className: "collapse-main-primary",
										label: "HR MONITORING",
										children: (
											<CollapseItemFacultyMonitoringGraph
												Highcharts={Highcharts}
											/>
										),
									},
							  ]
							: [
									{
										key: "1",
										className: "collapse-main-primary",
										label: "MONITORING",
										children: (
											<CollapseItemFacultyMonitoringGraph
												Highcharts={Highcharts}
											/>
										),
									},
							  ]
					}
				/>
			</Col>

			<Col xs={24} sm={24} md={8} lg={6} xl={6} xxl={6} className="card-list">
				<Row gutter={[20, 20]}>
					{dataSourceFacultyLoadMonitoringList &&
					dataSourceFacultyLoadMonitoringList.departmentList &&
					dataSourceFacultyLoadMonitoringList.departmentList.length > 0 ? (
						<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
							<FacultyMonitoringDepartmentPieGraph
								Highcharts={Highcharts}
								departmentList={
									dataSourceFacultyLoadMonitoringList &&
									dataSourceFacultyLoadMonitoringList.departmentList
										? dataSourceFacultyLoadMonitoringList.departmentList
										: []
								}
							/>
						</Col>
					) : null}

					<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
						<FacultyMonitoringCalendar />
					</Col>
				</Row>
			</Col>

			{userData().user_role_id !== 5 && (
				<Col
					xs={24}
					sm={24}
					md={24}
					lg={24}
					xl={24}
					xxl={24}
					className="card-list"
				>
					<FacultyMonitoringDepartmentListGraph
						Highcharts={Highcharts}
						dataSource={
							dataSourceFacultyLoadMonitoringList &&
							dataSourceFacultyLoadMonitoringList.department_list
								? dataSourceFacultyLoadMonitoringList.department_list
								: []
						}
					/>
				</Col>
			)}
		</Row>
	);
}
