import { Card, Col, Row } from "antd";

import FacultyGradeSubmissionGraph from "./FacultyGradeSubmissionGraph";

export default function FacultyGradeSubmissionGraphList(props) {
	const { Highcharts } = props;

	return (
		<Row>
			<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
				<Card title="Grade Submission">
					<FacultyGradeSubmissionGraph Highcharts={Highcharts} />
				</Card>
			</Col>
		</Row>
	);
}
