import { useEffect, useState } from "react";

import { GET } from "../../../../providers/useAxiosQuery";

export default function CollapseItemFacultyMonitoringNegativeGraph(props) {
	const { Highcharts } = props;

	const [filter, setFilter] = useState({
		action: "department",
		department: "",
		faculty: "",
		year: "",
		quarter: "",
		month: "",
		day: "",
	});

	const { refetch: refetchSource } = GET(
		`api/faculty_load_monitoring_faculty_absent_present_graph?${new URLSearchParams(
			filter
		)}`,
		"faculty_load_monitoring_faculty_absent_present_graph",
		(res) => {
			console.log("faculty_load_monitoring_faculty_absent_present_graph", res);

			if (res.data) {
				let data = res.data;
				let action = data.action;
				let downTo = data.downTo;

				let title = "TOP DEPARTMENT ABSENT";
				let subtitleText = "CLICK THE COLUMNS TO VIEW PER FACULTY";
				let xAxisTitle = "DEPARTMENT";

				if (action === "department") {
					title = "TOP FACULTY ABSENT";
					subtitleText = "CLICK THE COLUMNS TO VIEW PER FACULTY";
					xAxisTitle = "DEPARTMENT";
				} else if (action === "faculty") {
					title = "TOP FACULTY ABSENT";
					subtitleText = "CLICK THE COLUMNS TO VIEW PER YEAR";
					xAxisTitle = "FACULTY";
				} else if (action === "year") {
					title = "YEARLY";
					subtitleText = "CLICK THE COLUMNS TO VIEW PER QUARTER";
					xAxisTitle = "YEAR";
				} else if (action === "quarter") {
					title = "QUARTERLY";
					subtitleText = "CLICK THE COLUMNS TO VIEW PER MONTH";
					xAxisTitle = "QUARTER";
				} else if (action === "month") {
					title = "MONTHLY";
					subtitleText = "CLICK THE COLUMNS TO VIEW PER DAY";
					xAxisTitle = "MONTH";
				} else if (action === "day") {
					title = "DAILY";
					subtitleText = "CLICK THE COLUMNS TO GO BACK TO PER DEPARTMENT";
					xAxisTitle = "DAY";
				}

				let data_series_value_filter = data.data_series_value.filter(
					(f) => f.y !== 0
				);

				if (data_series_value_filter.length === 0) {
					subtitleText = "NO DATA TO VIEW";
				}

				if (document.getElementById("divFacultyMonitoringAbsentPresentGraph")) {
					let chart = Highcharts.chart(
						"divFacultyMonitoringAbsentPresentGraph",
						{
							chart: {
								type: "bar",
								events: {
									click: function (e) {
										let series_name =
											data.data_series_name[
												Math.abs(Math.round(e.xAxis[0].value))
											];

										if (action === "day") {
											setFilter((prevState) => ({
												...prevState,
												action: downTo,
											}));
										} else {
											setFilter((prevState) => ({
												...prevState,
												action: downTo,
												[action]: series_name,
											}));
										}

										let graphWrapper = document.querySelector(
											"#divFacultyMonitoringAbsentPresentGraphWrapper"
										);
										if (graphWrapper) {
											let highchartsDataTable = graphWrapper.querySelector(
												".highcharts-data-table"
											);
											if (highchartsDataTable) {
												highchartsDataTable.remove();
											}
										}
									},
								},
							},
							title: {
								text: title,
							},
							subtitle: {
								text: subtitleText,
							},
							xAxis: [
								{
									categories: data.data_series_name,
									reversed: false,
									labels: {
										step: 1,
									},
									accessibility: {
										description: "Absent",
									},
								},
								{
									// mirror axis on right side
									opposite: true,
									reversed: false,
									categories: data.data_series_name,
									linkedTo: 0,
									labels: {
										step: 1,
									},
									accessibility: {
										description: "Present",
									},
								},
							],
							yAxis: {
								title: {
									text: null,
								},
								labels: {
									format: "{abs value}%",
								},
								accessibility: {
									description: "Percentage population",
									rangeDescription: "Range: 0 to 5%",
								},
							},
							tooltip: {
								format:
									"<b>{series.name}, age {point.category}</b><br/>" +
									"Population: {(abs point.y):.2f}%",
							},
							// legend: false,
							plotOptions: {
								series: {
									// borderWidth: 0,
									// dataLabels: {
									// 	enabled: false,
									// 	// format: "{point.y:.2f}",
									// },
									cursor: "pointer",
									stacking: "normal",
									borderRadius: "50%",
								},
							},
							series: data.data_series_value,
							exporting: {
								filename: `report-revenue-${title.toLowerCase()}`,
								buttons: {
									contextButton: {
										symbolStroke: "#f58d13",
										symbolX: 14,
										menuItems: [
											"printChart",
											"separator",
											"downloadPNG",
											"downloadJPEG",
											"downloadPDF",
											"downloadSVG",
											"separator",
											// "downloadCSV",
											"toggleTable",
										],
									},
								},
								menuItemDefinitions: {
									// Custom definition
									toggleTable: {
										onclick: function () {
											if (
												this.dataTableDiv &&
												this.dataTableDiv.style.display !== "none"
											) {
												this.dataTableDiv.style.display = "none";
											} else {
												this.viewData();
												this.dataTableDiv.style.display = "";
											}
										},
										text: "View Data Table",
									},
								},
							},
						}
					);

					Highcharts.addEvent(chart, "aftergetTableAST", function (e) {
						e.tree.children[2].children.forEach(function (row) {
							row.children.forEach(function (cell, i) {
								if (i !== 0) {
									row.children[i].textContent = cell.textContent;
								}
							});
						});
					});
				}
			}
		},
		false
	);

	useEffect(() => {
		refetchSource();

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filter]);

	return (
		<div id="divFacultyMonitoringAbsentPresentGraphWrapper">
			<div id="divFacultyMonitoringAbsentPresentGraph" />
		</div>
	);
}
