import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Button, Col, Flex, Row, Table } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/pro-regular-svg-icons";

import { GET } from "../../../providers/useAxiosQuery";
import { apiUrl, userData } from "../../../providers/companyInfo";
import {
	TableGlobalSearchAnimated,
	TablePageSize,
	TablePagination,
	TableShowingEntriesV2,
	useTableScrollOnTop,
} from "../../../providers/CustomTableFilter";
import ModalUploadFile from "./components/ModalUploadFile";
import ModalUploadFileStatus from "./components/ModalUploadFileStatus";
import ModalUploadFileStatusPreview from "./components/ModalUploadFileStatusPreview";

export default function PageGradeFileStatus(props) {
	const { refetchPermissions } = props;

	const location = useLocation();

	const [selectedRowKeys, setSelectedRowKeys] = useState([]);

	const [toggleModalFormUploadFile, setToggleModalFormUploadFile] = useState({
		open: false,
		data: null,
	});
	const [toggleModalFormUploadFileStatus, setToggleModalFormUploadFileStatus] =
		useState(false);
	const [toggleModalFormUploadFileShow, setToggleModalFormUploadFileShow] =
		useState({
			open: false,
			data: null,
		});

	const [tableFilter, setTableFilter] = useState({
		page: 1,
		page_size: 50,
		search: "",
		sort_field: "created_at",
		sort_order: "desc",
		status:
			location.pathname === "/grade-tracker/approval" ? "Approval" : "Approved",
		user_id: userData().id,
		from: "grade_file",
	});

	useEffect(() => {
		let status = "";

		if (location.pathname === "/grade-tracker/approval") {
			status = "Approval";
		} else if (location.pathname === "/grade-tracker/approved") {
			status = "Approved";
		}

		setTableFilter({
			page: 1,
			page_size: 50,
			search: "",
			sort_field: "created_at",
			sort_order: "desc",
			status: status,
			user_id: userData().id,
			from: "grade_file",
		});

		return () => {};
	}, [location]);

	const { data: dataSource, refetch: refetchSource } = GET(
		`api/grade_file?${new URLSearchParams(tableFilter)}`,
		"grade_file_list",
		() => {
			refetchPermissions();
		}
	);

	const onChangeTable = (pagination, filters, sorter) => {
		setTableFilter((prevState) => ({
			...prevState,
			sort_field: sorter.columnKey,
			sort_order: sorter.order ? sorter.order.replace("end", "") : null,
			page: 1,
			page_size: "50",
		}));
	};

	useEffect(() => {
		if (dataSource) {
			refetchSource();
		}

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tableFilter]);

	useTableScrollOnTop("tblGradeFileApprovalList", location.pathname);

	return (
		<Row gutter={[15, 15]} id="tbl_wrapper">
			<Col xs={24} sm={24} md={24}>
				<div className="tbl-top-filter">
					<Flex gap={15}>
						<Button
							className={`btn-main-primary min-w-150 ${
								tableFilter.status === "Active" ? "active" : "outlined"
							}`}
							onClick={() => {
								setTableFilter((ps) => ({
									...ps,
									status: "Active",
								}));
							}}
						>
							Active
						</Button>

						<Button
							className={`btn-main-primary min-w-150 ${
								tableFilter.status === "Archived" ? "active" : "outlined"
							}`}
							onClick={() => {
								setTableFilter((ps) => ({
									...ps,
									status: "Archived",
								}));
							}}
						>
							Archived
						</Button>

						{selectedRowKeys.length > 0 && (
							<Button
								className="btn-main-primary"
								onClick={() => setToggleModalFormUploadFileStatus(true)}
							>
								Update Status
							</Button>
						)}
					</Flex>

					<TablePageSize
						tableFilter={tableFilter}
						setTableFilter={setTableFilter}
					/>
				</div>
			</Col>

			<Col xs={24} sm={24} md={24}>
				<div className="tbl-top-filter">
					<TableGlobalSearchAnimated
						tableFilter={tableFilter}
						setTableFilter={setTableFilter}
					/>

					<Flex gap={15}>
						<TableShowingEntriesV2 />

						<TablePagination
							tableFilter={tableFilter}
							setTableFilter={setTableFilter}
							total={dataSource?.data.total}
							showLessItems={true}
							showSizeChanger={false}
							tblIdWrapper="tbl_wrapper"
						/>
					</Flex>
				</div>
			</Col>

			<Col xs={24} sm={24} md={24}>
				<Table
					id="tblGradeFileApprovalList"
					className="ant-table-default ant-table-striped"
					dataSource={dataSource && dataSource.data.data}
					rowKey={(record) => record.id}
					pagination={false}
					bordered={false}
					onChange={onChangeTable}
					scroll={{ x: "max-content" }}
					sticky
					rowSelection={{ selectedRowKeys, onChange: setSelectedRowKeys }}
				>
					<Table.Column
						title="Preview Attachment"
						key="preview_attachment"
						align="center"
						render={(text, record) => {
							console.log("record", record);
							return (
								<Button
									type="link"
									onClick={() => {
										let attachments = record.attachments;
										let signature = null;

										if (record.faculty_load.profile.attachments.length > 0) {
											signature = apiUrl(
												record.faculty_load.profile.attachments[0].file_path
											);
										}

										let pdf_file = null;

										if (attachments.length > 0) {
											pdf_file = attachments[0].pdf_file;
										}

										setToggleModalFormUploadFileShow({
											open: true,
											data: {
												pdf_file,
												signature,
											},
										});
									}}
								>
									<FontAwesomeIcon icon={faEye} />
								</Button>
							);
						}}
					/>

					<Table.Column
						title="Notes"
						key="description"
						dataIndex="description"
						sorter
					/>
					<Table.Column
						title="Class Time"
						key="class_time"
						dataIndex="class_time"
						sorter
					/>
					<Table.Column
						title="Name"
						key="fullname"
						sorter
						dataIndex="fullname"
						width={120}
					/>

					<Table.Column
						title="Room"
						key="room_code"
						dataIndex="room_code"
						sorter
					/>
					<Table.Column title="Subject" key="code" dataIndex="code" sorter />
					<Table.Column
						title="School Year"
						key="school_year"
						dataIndex="school_year"
						sorter
					/>
					<Table.Column
						title="Semester"
						key="semester"
						dataIndex="semester"
						sorter
					/>
				</Table>
			</Col>
			<Col xs={24} sm={24} md={24}>
				<div className="tbl-bottom-filter">
					<TableShowingEntriesV2 />
					<TablePagination
						tableFilter={tableFilter}
						setTableFilter={setTableFilter}
						total={dataSource?.data.total}
						showLessItems={true}
						showSizeChanger={false}
						tblIdWrapper="tbl_wrapper"
					/>
				</div>
			</Col>

			<ModalUploadFile
				toggleModalFormUploadFile={toggleModalFormUploadFile}
				setToggleModalFormUploadFile={setToggleModalFormUploadFile}
			/>
			<ModalUploadFileStatus
				toggleModalFormUploadFileStatus={toggleModalFormUploadFileStatus}
				setToggleModalFormUploadFileStatus={setToggleModalFormUploadFileStatus}
				selectedRowKeys={selectedRowKeys}
				setSelectedRowKeys={setSelectedRowKeys}
			/>
			<ModalUploadFileStatusPreview
				toggleModalFormUploadFileShow={toggleModalFormUploadFileShow}
				setToggleModalFormUploadFileShow={setToggleModalFormUploadFileShow}
			/>
		</Row>
	);
}
