import { useEffect, useState } from "react";
import { FloatButton } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faChartCandlestick,
	faChartRadar,
} from "@fortawesome/pro-regular-svg-icons";
import Highcharts from "highcharts";

import { userData } from "../../../providers/companyInfo";
import highchartsSetOptions from "../../../providers/highchartsSetOptions";
import FacultyMonitoringGraphList from "./components/FacultyMonitoringGraphList";
import FacultyGradeSubmissionGraphList from "./components/FacultyGradeSubmissionGraphList";

require("highcharts/modules/accessibility")(Highcharts);
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);
require("highcharts/modules/boost")(Highcharts);

export default function PageDashboard(props) {
	const { refetchPermissions } = props;

	highchartsSetOptions(Highcharts);

	useEffect(() => {
		refetchPermissions();

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [toggleChartDisplay, setToggleChartDisplay] = useState("monitoring");

	const RenderChartDisplay = () => {
		if (toggleChartDisplay === "monitoring") {
			return <FacultyMonitoringGraphList Highcharts={Highcharts} />;
		} else if (toggleChartDisplay === "gradesubmission") {
			return <FacultyGradeSubmissionGraphList Highcharts={Highcharts} />;
		}
	};

	return (
		<>
			<RenderChartDisplay />

			{![3, 4, 5].includes(userData().user_role_id) ? (
				<>
					<FloatButton
						className="btn-float-menu"
						shape="square"
						icon={<FontAwesomeIcon icon={faChartRadar} />}
						onClick={() => setToggleChartDisplay("monitoring")}
						type={toggleChartDisplay === "monitoring" ? "primary" : ""}
						style={{
							top: 200,
							insetInlineEnd: 0,
						}}
						description="Monitoring Graph"
					/>

					<FloatButton
						className="btn-float-menu"
						shape="square"
						icon={<FontAwesomeIcon icon={faChartCandlestick} />}
						onClick={() => setToggleChartDisplay("gradesubmission")}
						type={toggleChartDisplay === "gradesubmission" ? "primary" : ""}
						style={{
							top: 250,
							insetInlineEnd: 0,
						}}
						description="Grade Submission Graph"
					/>
				</>
			) : null}
		</>
	);
}
