import { Routes, Route } from "react-router-dom";
import {
	faBooks,
	faHome,
	faUsers,
	faSheetPlastic,
	faScaleUnbalancedFlip,
	faChartGantt,
	faPieChart,
	faLayerPlus,
	faMobileAndroid,
} from "@fortawesome/pro-regular-svg-icons";

import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";

import Page404 from "../views/errors/Page404";

import PageLogin from "../views/public/PageLogin/PageLogin";
// import PageRegister from "../views/public/PageRegister/PageRegister";

import PageDashboard from "../views/private/PageDashboard/PageDashboard";
import PageEditProfile from "../views/private/PageEditProfile/PageEditProfile";

import PageFacultyLoad from "../views/private/PageFacultyLoad/PageFacultyLoad";
import PageFacultyLoadJustification from "../views/private/PageFacultyLoad/PageFacultyLoadJustification";
import PageFacultyLoadDeduction from "../views/private/PageFacultyLoad/PageFacultyLoadDeduction";
import PageFacultyLoadAbsentList from "../views/private/PageFacultyLoad/PageFacultyLoadAbsentList";
import PageFacultyLoadReport from "../views/private/PageFacultyLoad/PageFacultyLoadReport";

import PageSettings from "../views/private/PageReferences/PageSettings/PageSettings";
import PageGradeFile from "../views/private/PageGradeFile/PageGradeFile";
import PageGradeFileReports from "../views/private/PageGradeFile/PageGradeFileReports";
import PageGradeFileStatus from "../views/private/PageGradeFile/PageGradeFileStatus";
import PageEmailTemplate from "../views/private/PageEmailTemplate/PageEmailTemplate";
import PageMobileAPK from "../views/private/PageMobileAPK/PageMobileAPK";

export default function RouteList() {
	return (
		<Routes>
			<Route
				path="/"
				element={
					<PublicRoute title="LOGIN" pageId="PageLogin" component={PageLogin} />
				}
			/>

			{/* <Route
				path="/registration"
				element={
					<PublicRoute
						title="REGISTRATION"
						pageId="PageRegistration"
						component={PageRegister}
					/>
				}
			/> */}

			<Route
				path="/edit-profile"
				element={
					<PrivateRoute
						moduleName="Edit Profile"
						title="User"
						subtitle="VIEW / EDIT"
						pageId="PageUserProfile"
						pageHeaderIcon={faUsers}
						breadcrumb={[
							{
								name: "Dashboard",
								link: "/dashboard",
							},
							{
								name: "Edit Profile",
							},
						]}
						component={PageEditProfile}
					/>
				}
			/>

			<Route
				path="/dashboard"
				element={
					<PrivateRoute
						moduleCode="M-01"
						moduleName="Dashboard"
						title="Dashboard"
						subtitle="ADMIN"
						pageId="PageDashboard"
						pageHeaderIcon={faHome}
						breadcrumb={[
							{
								name: "Dashboard",
							},
						]}
						component={PageDashboard}
					/>
				}
			/>

			{/* faculty */}
			<Route
				path="/faculty-load/list"
				element={
					<PrivateRoute
						moduleCode="M-02"
						moduleName="Faculty Monitoring - List"
						title="Load"
						subtitle="FACULTY"
						pageId="PageFacultyLoad"
						pageHeaderIcon={faSheetPlastic}
						breadcrumb={[
							{
								name: "Dashboard",
								link: "/dashboard",
							},
							{
								name: "Faculty Load",
							},
							{
								name: "List",
							},
						]}
						component={PageFacultyLoad}
					/>
				}
			/>
			<Route
				path="/faculty-load/justification"
				element={
					<PrivateRoute
						moduleCode="M-03"
						moduleName="Faculty Monitoring - Justification"
						title="Faculty Monitoring"
						subtitle="JUSTIFICATION"
						pageId="PageFacultyLoadJustification"
						pageHeaderIcon={faScaleUnbalancedFlip}
						breadcrumb={[
							{
								name: "Dashboard",
							},
							{
								name: "Faculty Load",
							},
							{
								name: "Justification",
							},
						]}
						component={PageFacultyLoadJustification}
					/>
				}
			/>

			<Route
				path="/faculty-load/deduction"
				element={
					<PrivateRoute
						moduleCode="M-04"
						moduleName="Faculty Monitoring - Deduction"
						title="Faculty Monitoring"
						subtitle="DEDUCTION"
						pageId="PageFacultyLoadDeduction"
						pageHeaderIcon={faSheetPlastic}
						breadcrumb={[
							{
								name: "Dashboard",
								link: "/dashboard",
							},
							{
								name: "Faculty Load",
							},
							{
								name: "Deduction",
							},
						]}
						component={PageFacultyLoadDeduction}
					/>
				}
			/>

			<Route
				path="/faculty-load/absent-list"
				element={
					<PrivateRoute
						moduleCode="M-05"
						moduleName="Faculty Monitoring - Absents"
						title="Faculty Monitoring"
						subtitle="ABSENTS"
						pageId="PageFacultyLoadAbsentList"
						pageHeaderIcon={faChartGantt}
						breadcrumb={[
							{
								name: "Dashboard",
							},
							{
								name: "Faculty Load",
							},
							{
								name: "Absents",
							},
						]}
						component={PageFacultyLoadAbsentList}
					/>
				}
			/>
			<Route
				path="/faculty-load/present-list"
				element={
					<PrivateRoute
						moduleCode="M-06"
						moduleName="Faculty Monitoring - Presents"
						title="Faculty Monitoring"
						subtitle="PRESENTS"
						pageId="PageFacultyLoadAbsentList"
						pageHeaderIcon={faChartGantt}
						breadcrumb={[
							{
								name: "Dashboard",
							},
							{
								name: "Faculty Load",
							},
							{
								name: "Presents",
							},
						]}
						component={PageFacultyLoadAbsentList}
					/>
				}
			/>

			<Route
				path="/faculty-load/reports"
				element={
					<PrivateRoute
						moduleCode="M-07"
						moduleName="Faculty Load Report"
						title="Faculty Monitoring"
						subtitle="ABSENT"
						pageId="PageFacultyLoadReport"
						pageHeaderIcon={faChartGantt}
						breadcrumb={[
							{
								name: "Dashboard",
							},
							{
								name: "Faculty Load",
							},
							{
								name: "Report",
							},
						]}
						component={PageFacultyLoadReport}
					/>
				}
			/>
			{/* end faculty */}

			{/* Grade Tracker */}
			<Route
				path="/grade-tracker/list"
				element={
					<PrivateRoute
						moduleCode="M-08"
						moduleName="Grade Tracker - List"
						title="Grade Tracker"
						subtitle="LIST"
						pageId="PageGradeTracker"
						pageClassName="grade-tracker-list"
						pageHeaderIcon={faLayerPlus}
						breadcrumb={[
							{
								name: "Grade Submission",
							},
							{
								name: "List",
							},
						]}
						component={PageGradeFile}
					/>
				}
			/>

			<Route
				path="/grade-tracker/approval"
				element={
					<PrivateRoute
						moduleCode="M-09"
						moduleName="Grade Tracker - Approval"
						title="Grade Tracker"
						subtitle="APPROVAL"
						pageId="PageGradeTracker"
						pageClassName="grade-tracker-approval"
						pageHeaderIcon={faPieChart}
						breadcrumb={[
							{
								name: "Grade Submission",
							},
							{
								name: "Approval",
							},
						]}
						component={PageGradeFileStatus}
					/>
				}
			/>

			<Route
				path="/grade-tracker/approved"
				element={
					<PrivateRoute
						moduleCode="M-10"
						moduleName="Grade Tracker - Approved"
						title="Grade Tracker"
						subtitle="APPORVED"
						pageId="PageGradeTracker"
						pageClassName="grade-tracker-approved"
						pageHeaderIcon={faPieChart}
						breadcrumb={[
							{
								name: "Grade Submission",
							},
							{
								name: "Approved",
							},
						]}
						component={PageGradeFileStatus}
					/>
				}
			/>

			<Route
				path="/grade-tracker/reports"
				element={
					<PrivateRoute
						moduleCode="M-11"
						moduleName="Grade Tracker - Reports"
						title="Grade Tracker"
						subtitle="REPORTS"
						pageId="PageGradeTracker"
						pageClassName="grade-tracker-reports"
						pageHeaderIcon={faPieChart}
						breadcrumb={[
							{
								name: "Grade Submission",
							},
							{
								name: "Reports",
							},
						]}
						component={PageGradeFileReports}
					/>
				}
			/>

			{/* end Grade Tracker */}

			{/* settings */}

			<Route
				path="/settings"
				element={
					<PrivateRoute
						moduleCode="M-12"
						moduleName="Admin Settings"
						title="List"
						subtitle="SETTINGS"
						pageId="PageAdminSettings"
						pageHeaderIcon={faBooks}
						breadcrumb={[
							{
								name: "Dashboard",
								link: "/dashboard",
							},
							{
								name: "Settings",
							},
						]}
						component={PageSettings}
					/>
				}
			/>

			{/* end settings */}

			<Route
				path="/email-template"
				element={
					<PrivateRoute
						moduleCode="M-13"
						moduleName="Email Template"
						title="Template"
						subtitle="EMAIL"
						pageId="PageEmailTemplate"
						pageClassName="email-template"
						pageHeaderIcon={faPieChart}
						breadcrumb={[
							{
								name: "Email Template",
							},
						]}
						component={PageEmailTemplate}
					/>
				}
			/>

			<Route
				path="/mobile-apk"
				element={
					<PrivateRoute
						moduleCode="M-13"
						moduleName="Mobile APK"
						title="APK"
						subtitle="MOBILE"
						pageId="PageMobileAPK"
						pageClassName="mobile-apk"
						pageHeaderIcon={faMobileAndroid}
						breadcrumb={[
							{
								name: "Mobile APK",
							},
						]}
						component={PageMobileAPK}
					/>
				}
			/>

			<Route path="/404" element={<Page404 />} />
			<Route path="/*" element={<Page404 />} />
		</Routes>
	);
}
