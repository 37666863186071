import { useEffect } from "react";
import { Tabs } from "antd/lib";

import PageBuilding from "../../PageReferences/PageBuilding/PageBuilding";
import PageFloor from "../../PageReferences/PageFloor/PageFloor";
import PageRoom from "../../PageReferences/PageRoom/PageRoom";
import PageSubject from "../PageSubject/PageSubject";
import PageDepartment from "../PageDepartment/PageDepartment";
import PageRate from "../PageRate/PageRate";
import PageSection from "../PageSection/PageSection";
import PageSchoolYear from "../PageSchoolYear/PageSchoolYear";

export default function PageSettings(props) {
	const { refetchPermissions } = props;

	useEffect(() => {
		refetchPermissions();

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const tabListTitle = [
		{
			key: "2",
			label: "Building",
			children: <PageBuilding />,
			id: "Building",
		},
		{
			key: "3",
			label: "Floor",
			children: <PageFloor />,
		},
		{
			key: "4",
			label: "Room",
			children: <PageRoom />,
		},
		{
			key: "5",
			label: "Department",
			children: <PageDepartment />,
		},
		{
			key: "8",
			label: "School Year",
			children: <PageSchoolYear />,
		},

		{
			key: "9",
			label: "Section",
			children: <PageSection />,
		},
		{
			key: "10",
			label: "Subject",
			children: <PageSubject />,
		},
		{
			key: "12",
			label: "Rate",
			children: <PageRate />,
		},
	];

	const onChange = (key) => {
		console.log(key);
	};

	return (
		<Tabs
			defaultActiveKey="1"
			onChange={onChange}
			type="card"
			items={tabListTitle}
		/>
	);
}
