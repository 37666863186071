import { Card, Col, Row, Statistic } from "antd";
import CountUp from "react-countup";

const formatter = (value) => <CountUp end={value} separator="," />;

export default function FacultyMonitoringDepartmentListGraph(props) {
	const { dataSource } = props;

	const RenderGraph = () => {
		let departmentList = [];

		if (dataSource) {
			dataSource.forEach((item, index) => {
				departmentList.push(
					<Col xs={24} sm={12} md={8} lg={6} xl={6} xxl={6} key={index}>
						<Card title={item.department_name} extra={<>ABSENT</>}>
							<Statistic
								title="Present: "
								value={item.presentCount}
								formatter={formatter}
							/>
							<Statistic
								title="Absent: "
								value={item.absentCount}
								formatter={formatter}
							/>
						</Card>
					</Col>
				);
			});
		}

		return <Row gutter={[20, 20]}>{departmentList}</Row>;
	};

	return <RenderGraph />;
}
