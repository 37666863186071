import { Button, Card } from "antd";

export default function PageMobileAPK() {
	return (
		<Card>
			<Button
				type="link"
				href="/assets/apk/monitoring_mobilev1-1.apk"
				className="w-auto h-auto p-0"
			>
				Download Mobile APK
			</Button>
		</Card>
	);
}
